import { createRef, RefObject, useEffect, useRef } from "react";

interface OwnProps {
	onPress: () => void;
	onRelease: () => void;
}

type Props = OwnProps;

const useJump = ({
	onPress,
	onRelease,
}: Props): RefObject<HTMLButtonElement> => {
	const button = createRef<HTMLButtonElement>();
	const locked = useRef("");
	const isJump = useRef<boolean>(false);

	useEffect(() => {
		const lock = () => {
			locked.current = "lock";
			if (isJump.current) onRelease();
			else onPress();
			isJump.current = !isJump.current;
		};

		const unlock = () => {
			locked.current = "";
		};

		// OnClick
		const onClickCallback = (e: MouseEvent) => {
			const jumpHandler = (e.target as HTMLButtonElement).getAttribute(
				"data-jump"
			);
			if (!locked.current && jumpHandler) {
				lock();
				setTimeout(unlock, 100);
			}
		};
		button.current?.addEventListener("click", onClickCallback);

		// OnPress
		const onPressCallback = (e: KeyboardEvent) => {
			if (!locked.current && e.key == " ") {
				lock();
			}
		};
		document.addEventListener("keypress", onPressCallback);
		const onReleaseCallback = (e: any) => {
			if (locked.current && e.key == " ") {
				unlock();
			}
		};
		document.addEventListener("keyup", onReleaseCallback);

		return () => {
			button.current?.removeEventListener("click", onClickCallback);

			document.removeEventListener("keypress", onPressCallback);
			document.removeEventListener("keyup", onReleaseCallback);
		};
	}, [onPress, onRelease]);

	return button;
};

export default useJump;
