import { graphql, useStaticQuery, withPrefix } from "gatsby";
import { useCallback, useEffect, useMemo } from "react";

export type Article = {
	ACCESSORIES: Array<{
		id: string;
		name: string;
		src: string;
	}>;
	TOP: Array<{
		id: string;
		name: string;
		src: string;
	}>;
	BOTTOM: Array<{
		id: string;
		name: string;
		src: string;
	}>;
	SHOES: Array<{
		id: string;
		name: string;
		src: string;
	}>;
};

const articles: {
	[key: string]: {
		ACCESSORIES: Array<string>;
		TOP: Array<string>;
		BOTTOM: Array<string>;
		SHOES: Array<string>;
	};
} = {
	"1-A1": {
		ACCESSORIES: ["furioso"],
		TOP: ["satine"],
		BOTTOM: ["flying-fox"],
		SHOES: [],
	},
	"2-A2": {
		ACCESSORIES: ["sea-biscuit"],
		TOP: ["treasure"],
		BOTTOM: ["lucky"],
		SHOES: [],
	},
	"EX-1": {
		ACCESSORIES: ["sea-biscuit"],
		TOP: [],
		BOTTOM: [],
		SHOES: [],
	},
	"3-B1": {
		ACCESSORIES: [],
		TOP: ["storm-bird", "vent-du-sud"],
		BOTTOM: ["meadow", "green-field"],
		SHOES: ["summer-bird"],
	},
	"4-B2": {
		ACCESSORIES: [],
		TOP: ["vent-du-sud"],
		BOTTOM: ["meadow", "green-field"],
		SHOES: ["summer-bird"],
	},
	"5-B3": {
		ACCESSORIES: [],
		TOP: ["billie"],
		BOTTOM: ["meadow", "green-field"],
		SHOES: ["summer-bird"],
	},
	"6-B3b": {
		ACCESSORIES: ["sea-biscuit"],
		TOP: ["sadie", "tillie", "billie"],
		BOTTOM: ["meadow", "green-field"],
		SHOES: ["summer-bird"],
	},
	"7-B4": {
		ACCESSORIES: [],
		TOP: ["antiope", "sadie", "tillie", "billie"],
		BOTTOM: ["meadow", "green-field"],
		SHOES: ["summer-bird"],
	},
	"8-B5": {
		ACCESSORIES: [],
		TOP: ["montjeu", "sadie", "tillie", "billie"],
		BOTTOM: ["meadow", "green-field"],
		SHOES: ["summer-bird"],
	},
	"9-C1": {
		ACCESSORIES: ["jumper"],
		TOP: ["ulysse"],
		BOTTOM: ["phoenix", "eclipse"],
		SHOES: [],
	},
	"10-D1": {
		ACCESSORIES: [],
		TOP: ["etincelle", "starlight", "pony"],
		BOTTOM: ["daisy"],
		SHOES: ["summer-bird"],
	},
	"11-D2": {
		ACCESSORIES: ["jumper"],
		TOP: ["smoky", "etincelle", "starlight", "pony"],
		BOTTOM: ["daisy"],
		SHOES: ["summer-bird"],
	},
	"12-E1": {
		ACCESSORIES: ["beaumanoir"],
		TOP: ["appaloosa"],
		BOTTOM: ["sabot-de-feu"],
		SHOES: [],
	},
	"13-F1": {
		ACCESSORIES: [],
		TOP: ["spirit"],
		BOTTOM: ["kalamity"],
		SHOES: ["feu-follet"],
	},
	"14-F2": {
		ACCESSORIES: [],
		TOP: ["poppy", "spirit"],
		BOTTOM: ["kalamity"],
		SHOES: ["feu-follet"],
	},
	"15-F3": {
		ACCESSORIES: ["tempete"],
		TOP: ["ginger", "poppy", "spirit"],
		BOTTOM: ["kalamity"],
		SHOES: ["feu-follet"],
	},
	"16-G1": {
		ACCESSORIES: ["praline"],
		TOP: ["galopette"],
		BOTTOM: ["pirouette"],
		SHOES: ["feu-follet"],
	},
	"17-H1": {
		ACCESSORIES: ["furioso"],
		TOP: ["belle"],
		BOTTOM: ["daisy"],
		SHOES: ["summer-bird"],
	},
	"18-L1": {
		ACCESSORIES: [],
		TOP: ["penelope", "sugar", "jolie-coeur"],
		BOTTOM: ["daisy"],
		SHOES: [],
	},
	"19-L2": {
		ACCESSORIES: [],
		TOP: ["penelope", "sugar", "pony"],
		BOTTOM: ["daisy"],
		SHOES: [],
	},
	"20-J1": {
		ACCESSORIES: [],
		TOP: ["petit-galop"],
		BOTTOM: ["sweet-boy"],
		SHOES: [],
	},
	"21-J2": {
		ACCESSORIES: [],
		TOP: ["andromaque"],
		BOTTOM: ["sweet-boy"],
		SHOES: [],
	},
	"EX-2": {
		ACCESSORIES: [],
		TOP: ["andromaque"],
		BOTTOM: [],
		SHOES: [],
	},
	"22-K1": {
		ACCESSORIES: [],
		TOP: ["lady-shadow", "thunder-snow"],
		BOTTOM: ["tornado"],
		SHOES: [],
	},
	"EX-3": {
		ACCESSORIES: [],
		TOP: [],
		BOTTOM: ["tornado"],
		SHOES: [],
	},
	"EX-4": {
		ACCESSORIES: [],
		TOP: ["nuage-noir", "lady-shadow", "thunder-snow"],
		BOTTOM: [],
		SHOES: [],
	},
	"23-K2": {
		ACCESSORIES: [],
		TOP: ["nuage-noir", "lady-shadow", "thunder-snow"],
		BOTTOM: ["tornado"],
		SHOES: [],
	},
	"24-K3": {
		ACCESSORIES: [],
		TOP: ["nuage-noir", "petit-crin", "thunder-snow"],
		BOTTOM: ["tornado"],
		SHOES: [],
	},
	"25-L1": {
		ACCESSORIES: [],
		TOP: ["lueur-detoile", "bijou"],
		BOTTOM: ["ruby"],
		SHOES: [],
	},
};

const links: { [key: string]: string } = {
	andromaque: "andromaque",
	antiope: "antiope",
	appaloosa: "appaloosa",
	beaumanoir: "beaumanoir",
	belle: "belle-1",
	bijou: "bijou",
	billie: "billie",
	daisy: "daisy",
	eclipse: "eclipse",
	etincelle: "etincelle",
	"feu-follet": "feu-follet",
	"flying-fox": "flying-fox",
	furioso: "furioso",
	galopette: "galopette",
	ginger: "ginger",
	"green-field": "green-field-1",
	"jolie-coeur": "jolie-coeur",
	jumper: "jumper",
	kalamity: "kalamity",
	"lady-shadow": "lady-shadow",
	lucky: "lucky",
	meadow: "meadow",
	montjeu: "montjeu",
	"nuage-noir": "nuage-noir",
	penelope: "penelope",
	"petit-crin": "petit-crin",
	"petit-galop": "petit-galop",
	phoenix: "phoenix",
	pirouette: "pirouette",
	pony: "pony",
	poppy: "poppy",
	praline: "praline",
	"sabot-de-feu": "sabot-de-feu",
	sadie: "sadie",
	satine: "satine",
	"sea-biscuit": "sea-biscuit",
	smoky: "smoky",
	spirit: "spirit",
	starlight: "starlight",
	"storm-bird": "storm-bird",
	sugar: "sugar",
	"summer-bird": "summer-bird",
	"vent-du-sud": "vent-du-sud",
	ulysse: "ulysse",
	tempete: "tempete",
	tornado: "tornado",
	tillie: "tillie",
	"sweet-boy": "sweet-boy",
	"lueur-detoile": "lueur-detoile",
	ruby: "ruby",
	treasure: "treasure",
	"thunder-snow": "thunder-snow",
};

const timeline: Array<{ time: number; look: string }> = [
	{ time: 0, look: "1-A1" },
	{ time: 5.55, look: "2-A2" },
	{ time: 9.05, look: "EX-1" },
	{ time: 12.55, look: "2-A2" },
	{ time: 15, look: "3-B1" },
	{ time: 18.55, look: "4-B2" },
	{ time: 20, look: "5-B3" },
	{ time: 20.55, look: "4-B2" },
	{ time: 21.35, look: "5-B3" },
	{ time: 22, look: "6-B3b" },
	{ time: 23, look: "7-B4" },
	{ time: 28, look: "8-B5" },
	{ time: 32, look: "9-C1" },
	{ time: 40, look: "11-D2" },
	{ time: 40.5, look: "10-D1" },
	{ time: 41.55, look: "11-D2" },
	{ time: 46.5, look: "12-E1" },
	{ time: 50, look: "14-F2" },
	{ time: 51.35, look: "15-F3" },
	{ time: 53, look: "14-F2" },
	{ time: 54.25, look: "13-F1" },
	{ time: 58.45, look: "14-F2" },
	{ time: 60, look: "15-F3" },
	{ time: 63.35, look: "16-G1" },
	{ time: 70.05, look: "17-H1" },
	{ time: 76.1, look: "18-L1" },
	{ time: 77.55, look: "19-L2" },
	{ time: 79.55, look: "18-L1" },
	{ time: 80.35, look: "19-L2" },
	{ time: 84, look: "20-J1" },
	{ time: 94, look: "21-J2" },
	{ time: 94.55, look: "20-J1" },
	{ time: 95.55, look: "EX-2" },
	{ time: 98.35, look: "EX-4" },
	{ time: 101.55, look: "EX-3" },
	{ time: 104, look: "22-K1" },
	{ time: 105.55, look: "23-K2" },
	{ time: 107.55, look: "24-K3" },
	{ time: 108.35, look: "25-L1" },
	{ time: 110.3, look: "24-K3" },
	{ time: 112, look: "EX-4" },
	{ time: 113.1, look: "24-K3" },
];

export default (time: number): Article => {
	const data = useStaticQuery(graphql`
		query ArticleQuery {
			allFile(filter: { relativePath: { regex: "/.*articles.*\\.png/" } }) {
				nodes {
					publicURL
					relativePath
					name
					childImageSharp {
						resize(width: 300) {
							src
						}
					}
				}
			}
		}
	`);

	const medias: [{ name: string; src: string }] = useMemo(
		() =>
			data.allFile.nodes.map((n: { name: string; childImageSharp: any }) => ({
				id: links[n.name],
				name: n.name,
				src: withPrefix(n.childImageSharp.resize.src),
			})),
		[data]
	);
	const current = timeline.filter((v) => v.time <= time);
	const article = articles[current[current.length - 1]?.look || "24-K3"];

	const DataToArticle = useCallback(
		(a: {
			ACCESSORIES: Array<string>;
			TOP: Array<string>;
			BOTTOM: Array<string>;
			SHOES: Array<string>;
		}): Article => {
			return {
				ACCESSORIES: medias.filter((m) => a.ACCESSORIES.includes(m.name)),
				TOP: medias.filter((m) => a.TOP.includes(m.name)),
				BOTTOM: medias.filter((m) => a.BOTTOM.includes(m.name)),
				SHOES: medias.filter((m) => a.SHOES.includes(m.name)),
			} as Article;
		},
		[medias]
	);

	useEffect(() => {
		const fetchs = [
			timeline[current.length - 1],
			timeline[current.length],
			timeline[current.length + 1],
		];

		fetchs.forEach((look) => {
			if (look) {
				const article = DataToArticle(articles[look.look]!);

				[
					...article.ACCESSORIES,
					...article.TOP,
					...article.BOTTOM,
					...article.SHOES,
				].forEach(({ src }) => fetch(src));
			}
		});
	}, [article]);

	return article
		? DataToArticle(article)
		: ({
				ACCESSORIES: [],
				TOP: [],
				BOTTOM: [],
				SHOES: [],
		  } as Article);
};
