import React, {
	ComponentType,
	useEffect,
	forwardRef,
	RefObject,
	ForwardRefExoticComponent,
	PropsWithoutRef,
	RefAttributes,
	HTMLProps,
} from "react";

type PlayerProps = {
	playing: boolean;
	timeUpdate: (time: number) => void;
} & HTMLProps<HTMLVideoElement>;

export default <P extends PlayerProps>(
	WrappedComponent: ComponentType<P>
): ForwardRefExoticComponent<
	PropsWithoutRef<P> & RefAttributes<HTMLVideoElement>
> => {
	const withPlayer = forwardRef<HTMLVideoElement, P>(
		({ timeUpdate, playing = false, ...props }, ref) => {
			// On time update : tracking video current time
			useEffect(() => {
				const player = (ref as RefObject<HTMLVideoElement>).current;
				const timeupdate = () => timeUpdate(player?.currentTime || 0);

				player?.addEventListener("timeupdate", timeupdate);

				return () => {
					player?.removeEventListener("timeupdate", timeupdate);
				};
			}, [timeUpdate]);

			// Playing : using boolean for play/pause video
			useEffect(() => {
				const player = (ref as RefObject<HTMLVideoElement>).current;
				if (playing) player?.play();
				else {
					player?.pause();
					timeUpdate(player?.currentTime || 0);
				}
			}, [playing, timeUpdate]);

			return <WrappedComponent ref={ref} {...(props as P)} />;
		}
	);

	return withPlayer;
};
