import styled from "@emotion/styled";
import { VideoStretch } from "@gx-ui";
import withPlayer from "@src/contexts/PlayerContext";

const HomeVideo = styled(VideoStretch)`
	position: absolute;
	object-position: center center;
`;

export default withPlayer(HomeVideo);
