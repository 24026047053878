import styled from "@emotion/styled";

export default styled.main`
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 10;
`;
